import React from "react";
import "./fonts/font.css";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocaleProvider from "./utils/localeProvider";
import "./i18n";

export const theme = {
  greenColor: "#63C55E",
  darkColor: "#434752",
  greyColor: "#A4A6A9",
  lightGreyColor: "#E6E9EC",
  lightColor: "#F8F9FA",
  stoneWhite: "#FFFFFF",

  errorColor: "#EC4331",

  lowResolutionDesktopMaxWidth: "1350px",
  mobileUIThreshold: "992px",
}

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul[class],
  ol[class] {
    padding: 0;
  }
  
  .hamburger-menu-active {
    height: 100vh;
    overflow: hidden;
    
    .slick-dots {
      z-index: 0 !important;
    }
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Set core body defaults */
  body {

    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  /* Remove list styles on ul, ol elements with a class attribute */
  ul[class],
  ol[class] {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  body {
    font-family: Qanelas, sans-serif;
    color: ${theme.darkColor};
    font-size: 14px;
    
    @media(max-width: ${theme.mobileUIThreshold}) {
      font-size: 16px;
    }
  }

  * {
    box-sizing: border-box;
  }

  button, input {
    outline: 0;

    &:focus {
      outline: 0;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  
  p {
    font-size: 1rem;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 600;
  }
  
  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  
  h3 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  
  h4 {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }

  p, ul, h1, h2, h3, h4 {
    font-family: Qanelas, sans-serif;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .input-error {
    display: block;
    text-align: left;
    color: #ec4331 !important;
    font-family: "Qanelas-Medium";
    font-size: 1rem;
    font-weight: 500;
    line-height: 22px;
    border-color: #ec4331 !important;

    input,
    [class*="Input-module--root"] {
      border-color: #ec4331 !important;
      color: #ec4331 !important;
    }

    @media(max-width: ${theme.mobileUIThreshold}) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  input {
    font-size: 1rem;
    
    &::placeholder {
      color: #a4a6a9;
    }
    
    @media(max-width: ${theme.mobileUIThreshold}) {
      font-size: 16px;
      
      &::placeholder {
        font-size: 16px;
      }
    }
  }

  textarea {
    font-family: Qanelas;
    font-weight: 500;
    color: #434752;
    border: 1px solid #e6e9ec;
    background-color: transparent;
    width: 100%;
    height: 150px;
    padding: 10px 20px;
    font-size: 1rem;

    &::placeholder {
      font-family: Qanelas;
      font-weight: 500;
      color: #a4a6a9;
      font-size: 1rem;
    }

    @media(max-width: ${theme.mobileUIThreshold}) {
      font-size: 1rem;

      &::placeholder {
        font-size: 1rem;
      }
    }
  }

  .h-divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #e6e9ec;
  }

  .v-divider {
    width: 1px;
    height: 100%;
    border-right: 1px solid #e6e9ec;
  }

  .line-separator {
    display: inline-block;
    height: 10px;
    width: 1px;
    margin: 0 20px;
    background: #e6e9ec;
    transform: rotate(90deg);
  }

  .box-shadow {
    box-shadow: 0 10px 20px -3px rgba(0, 0, 0, 0.07);
  }

  .secondary-text {
    color: #a4a6a9;
  }

  .light-color {
    color: #a4a6a9;
  }

  .strong-color {
    color: #434752;
  }

  .green-color {
    color: #63c55e;
  }

  .error-color {
    color: #ec4331;
  }

  .border {
    border: 1px solid #E6E9EC;
  }

  .dashed-line {
    border-bottom: 1px dashed #e6e9ec;
    width: 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }

  .d-inline {
    display: inline-block;
  }

  .d-flex {
    display: flex;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .mr-auto {
    margin-right: auto;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mb-auto {
    margin-bottom: auto;
  }

  .mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mt-0 {
    margin-top: 0;
  }

  .mr-0 {
    margin-right: 0;
  }

  .mb-0 {
    margin-bottom: 0;
  }

  .ml-0 {
    margin-left: 0;
  }

  .mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }

  .my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .mt-1 {
    margin-top: 4px;
  }

  .mr-1 {
    margin-right: 4px;
  }

  .mb-1 {
    margin-bottom: 4px;
  }

  .ml-1 {
    margin-left: 4px;
  }

  .mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }

  .my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .mt-2 {
    margin-top: 8px;
  }

  .mr-2 {
    margin-right: 8px;
  }

  .mb-2 {
    margin-bottom: 8px;
  }

  .ml-2 {
    margin-left: 8px;
  }

  .mx-3 {
    margin-left: 16px;
    margin-right: 16px;
  }

  .my-3 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .mt-3 {
    margin-top: 16px;
  }

  .mr-3 {
    margin-right: 16px;
  }

  .mb-3 {
    margin-bottom: 16px;
  }

  .ml-3 {
    margin-left: 16px;
  }

  .mx-4 {
    margin-left: 24px;
    margin-right: 24px;
  }

  .my-4 {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .mt-4 {
    margin-top: 24px;
  }

  .mr-4 {
    margin-right: 24px;
  }

  .mb-4 {
    margin-bottom: 24px;
  }

  .ml-4 {
    margin-left: 24px;
  }

  .mx-5 {
    margin-left: 48px;
    margin-right: 0;
  }

  .my-5 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .mt-5 {
    margin-top: 48px;
  }

  .mr-5 {
    margin-right: 48px;
  }

  .mb-5 {
    margin-bottom: 48px;
  }

  .ml-5 {
    margin-left: 48px;
  }

  .px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .pt-0 {
    padding-top: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pb-0 {
    padding-bottom: 0;
  }

  .pl-0 {
    padding-left: 0;
  }

  .px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }

  .py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .pt-1 {
    padding-top: 4px;
  }

  .pr-1 {
    padding-right: 4px;
  }

  .pb-1 {
    padding-bottom: 4px;
  }

  .pl-1 {
    padding-left: 4px;
  }

  .px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .pt-2 {
    padding-top: 8px;
  }

  .pr-2 {
    padding-right: 8px;
  }

  .pb-2 {
    padding-bottom: 8px;
  }

  .pl-2 {
    padding-left: 8px;
  }

  .px-3 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .py-3 {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .pt-3 {
    padding-top: 16px;
  }

  .pr-3 {
    padding-right: 16px;
  }

  .pb-3 {
    padding-bottom: 16px;
  }

  .pl-3 {
    padding-left: 16px;
  }

  .px-4 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .py-4 {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .pt-4 {
    padding-top: 24px;
  }

  .pr-4 {
    padding-right: 24px;
  }

  .pb-4 {
    padding-bottom: 24px;
  }

  .pl-4 {
    padding-left: 24px;
  }

  .px-5 {
    padding-left: 48px;
    padding-right: 48px;
  }

  .py-5 {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .pt-5 {
    padding-top: 48px;
  }

  .pr-5 {
    padding-right: 48px;
  }

  .pb-5 {
    padding-bottom: 48px;
  }

  .pl-5 {
    padding-left: 48px;
  }

  .position-relative {
    position: relative;
  }

  .position-absolute {
    position: absolute;
  }

  .font-weight-bold {
    font-weight: 700;
  }

  .font-weight-600 {
    font-weight: 600;
  }

  .font-weight-light {
    font-weight: 500;
  }

  .w-100 {
    width: 100%;
  }

  .text-center {
    text-align: center;
  }

  .bg-white {
    background-color: white;
  }

  .bg-grey {
    background-color: #F8F9FA;
  }

  .bg-dark-grey {
    background-color: ${props => props.theme.darkColor};
  }

  .color-white {
    color: white;
  }

  .green-color {
    color: ${props => props.theme.greenColor};
  }

  .grey-color {
    color: ${props => props.theme.greyColor}
  }

  .font-s-14 {
    font-size: 14px;
  }

  .font-s-36 {
    font-size: 36px;
  }

  .font-s-16 {
    font-size: 16px;
  }

  .font-s-18 {
    font-size: 18px;
  }

  .font-s-48 {
    font-size: 48px;
  }

  .pointer {
    cursor: pointer;
  }
  ${
          "" /* 
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f8f9fa;
}

::-webkit-scrollbar-thumb {
    background: #ffffff;
    border: 1px solid #e6e9ec;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #a4a6a9;
} */
  }

`

export default function AppWrapper({
                                     element,
                                     props,
                                   }) {
  return (
    <LocaleProvider
      location={props.location}
      pathLocale={props.pageContext.locale}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {element}
      </ThemeProvider>
    </LocaleProvider>
  )
}
