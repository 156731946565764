const INITIAL_STATE = {
  expanded: true,
  scrolled: false
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case "TOGGLE_EXPAND":
      return {
        ...state,
        expanded: action.expanded
      }
    case "TOGGLE_SCROLLED":
      return {
        ...state,
        scrolled: action.scrolled
      }
    default:
      return state;
  }
}